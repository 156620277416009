import {Container, Grid, List, ListItem} from '@mui/material'
import '~@app/module/home/component/section/OutroSection.scss'
import Link from '@mui/material/Link'
import React, {FC, HTMLProps, useMemo} from 'react';
import useApp from '~@app/component/app/UseApp'
import HtmlViewer, {html2paragraph} from '~@app/module/common/component/HtmlViewer'
import useFamily from '~@app/module/family/context/FamilyContext'
import {trans} from '~@core/util/StringUtil'

export type BlogSectionProps = HTMLProps<HTMLDivElement>

const BlogSection: FC<BlogSectionProps> = ({className, ...props}) => {
  const app = useApp()
  const {family} = useFamily();
  if (!family) {
    return null;
  }

  const text = useMemo(() => {
    if (family?.properties?.allPageFooterContent) {
      return <HtmlViewer value={family?.properties?.allPageFooterContent ?? ''}/>
    } 
    return <div>
        Lewis family roots reach back to the late <b>19th century</b> England. Founded by John Lewis, the middle-class
        entrepreneur, who married Mary Wright and together they started a family which now spans over four
        generations.
    </div>
    
  }, [family])

  return <div className={`outro-section section ${className || ''}`} {...props}>
    <Container>
      <Grid container columns={12}>
        <Grid item md={8} xs={12} className="outro-title">
          {text}
        </Grid>
        <Grid item md={2} className="outro-nav" sx={{fontWeight: 600}}>
          <List>
            <ListItem>
              <Link href={app.pageUrl('/family')}>{trans('family:membersDirectory')}</Link>
            </ListItem>
            <ListItem>
              <Link href={app.pageUrl('/family/member')}>{trans('family:familyTree')}</Link>
            </ListItem>
            <ListItem>
              <Link href={app.pageUrl('family/timeline')}>{trans('family:familyTimeline')}</Link>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={2} className="outro-nav" sx={{fontWeight: 600}}>
          <List>
            <ListItem>
              <Link href={app.pageUrl('/gallery')}>{trans('galleries')}</Link>
            </ListItem>
            <ListItem>
              <Link href={app.pageUrl('/blog')}>{trans('blogs')}</Link>
            </ListItem>
            <ListItem>
              <Link href={app.pageUrl('/')}>{trans('home')}</Link>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  </div>
};

export default BlogSection;