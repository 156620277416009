import {useContext} from 'react';
import {PageContext} from '~@app/component/page/PageProvider';
import BaseError from "~@core/error/BaseError";

export default function usePage() {
  const ret = useContext(PageContext);
  if (!ret) {
    throw new BaseError('Not in Page Context');
  }
  return ret;
}