import _ from 'lodash'
import React, {FC, HTMLProps, useMemo} from 'react';
import '~@app/module/common/component/HtmlViewer.scss'
import sanitizeHtml from 'sanitize-html'

const DEFAULT_OPTION: sanitizeHtml.IOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    '*': ['style']
  }
}

export interface HtmlViewerProps extends HTMLProps<HTMLDivElement> {
  value?: string
}

// escape nested <p><br></p> tags
const exp = /<p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<\/p>/g

const HtmlViewer: FC<HtmlViewerProps> = ({value, className, ...props}) => {
  const html = useMemo(() => {
    if (value === null || value === undefined) {
      return ''
    }
    const txt = `${value}`.replace(exp, '')
    return sanitizeHtml(txt, {...DEFAULT_OPTION})
  }, [value])

  // console.log(value)
  // console.log(html)

  return <div className={`html-viewer ${className || ''}`} {...props}>
    <div dangerouslySetInnerHTML={{__html: html || ''}}/>
  </div>
}

export function html2paragraph(html: string, words = 50, overtext = '...') {
  // const decoredTxt = html
  //   .replaceAll('</p>',"</p>\n")
  //   .replaceAll('</P>',"</P>\n")
  //   .replaceAll('</br>',"</br>\n")
  //   .replaceAll('</BR>',"</BR>\n")
  //   .value();
  // let txt = `${html}`.replace(exp, '')
  const txt = sanitizeHtml(html, {
    allowedTags: []
  })

  const texts = _.words(txt);
  const ret = texts.slice(0, words).join(' ');
  return texts.length > words ? ret + overtext : ret;
}

export default HtmlViewer;