import React, {createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react"
import useApp from "~@app/component/app/UseApp"
import useAuth from "~@app/component/auth/UseAuth"
import usePage from "~@app/component/page/UsePage"
import Family from "~@app/module/core/model/Family"
import FamilyService from "~@app/module/core/service/FamilyService"
import BaseError from "~@core/error/BaseError"
import {useAsyncEffect} from "~@core/hook/ReactHooks"
import StorageUtil from "~@core/util/StorageUtil"

export type FamilyContextType = {
  family?: Family
  reloadFamily?: () => Promise<Family | undefined>
}
export const FamilyContext = createContext<FamilyContextType | null>(null);

export interface FamilyContextProviderProps extends PropsWithChildren {
  familyId?: string
}

export const FamilyContextProvider: FC<FamilyContextProviderProps> = ({
  children
}) => {
  const [family, setFamily] = useState<Family>()
  const [isLoaded, setIsLoaded] = useState(false)
  const auth = useAuth();
  const app = useApp();
  const page = usePage()

  useAsyncEffect(async () => {
    await loadFamily();
    setIsLoaded(true)
  }, []);

  const loadFamily = async () => {
    const userFamilyId = auth?.me?.familyId;
    const code = app.getParam('familyCode');

    const [data] = await FamilyService.getByCode(code, {
      with: ['*', 'properties'],
    });

    const isFamilyMember = data?.id && data?.id === userFamilyId;
    const isAdmin = auth?.me?.role === 'admin';
    if (data?.id && (isFamilyMember || isAdmin)) {
      setFamily(data);
      StorageUtil.set('lastFamilyCode', code);
      return data;
    } else {
      if (code) {
        app.notFound();
      }
      setFamily(undefined)
      return undefined;
    }
  }

  useEffect(() => {
    if (family?.name) {
      page.setPageTitleSuffix(family.name);
    }
  }, [family]);

  const contextValue: FamilyContextType = useMemo(() => ({
    family,
    async reloadFamily() {
      return await loadFamily();
    }
  }), [family]);


  return (
    <FamilyContext.Provider value={contextValue}>
      {isLoaded ? children : null}
    </FamilyContext.Provider>
  );
}

export default function useFamily() {
  const ret = useContext(FamilyContext);
  if (!ret) {
    throw new BaseError('Not in Family Context');
  }
  return ret;
}