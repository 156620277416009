import React, {FC, PropsWithChildren} from 'react';
import Empty from "~@core/component/common/Empty";
import {AnyNumber, AnyString} from "~@core/type/Common"

export interface ImageProps extends PropsWithChildren {
  url?: AnyString
  title?: AnyString
  alt?: AnyString
  width?: AnyNumber | AnyString
  height?: AnyNumber | AnyString
  className?: AnyString
}

const Image: FC<ImageProps> = ({url, alt, title, width, height, className}) => {
  if (!url) {
    return <Empty/>
  }
  return <img src={url || undefined}
              alt={alt || undefined}
              title={title || undefined}
              width={width || undefined}
              height={height || undefined}
              className={`app-img ${className || ''}`}
  />;
}
export default Image