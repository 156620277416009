import React, {createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState,} from 'react';
import PageContextType from '~@app/component/page/PageContextType';
import {BreadcrumbItem} from '~@core/component/Type';

export const PageContext = createContext<PageContextType | null>(null);

const PageProvider = ({children}: PropsWithChildren<unknown>): React.ReactElement => {
  const [bcrumbs, setBcrumbs] = useState<BreadcrumbItem[]>();
  const [title, setTitle] = useState<string>();
  const [titleSuffix, setTitleSuffix] = useState<string>()

  const getBreadcrumbs = useCallback(() => bcrumbs, [bcrumbs]);
  const setBreadcrumbs = useCallback((breadcrumbs) => {
    setBcrumbs(breadcrumbs);
  }, []);
  const getPageTitle = useCallback(() => title, [title]);
  const setPageTitle = useCallback((title) => {
    setTitle(title);
  }, []);
  const getPageTitleSuffix = useCallback(() => titleSuffix, [titleSuffix]);
  const setPageTitleSuffix = useCallback((titleSuffix) => {
    setTitleSuffix(titleSuffix);
  }, []);

  const contextValue = useMemo(() => ({
    getBreadcrumbs,
    setBreadcrumbs,
    getPageTitle,
    setPageTitle,
    getPageTitleSuffix,
    setPageTitleSuffix,
  } as PageContextType), [bcrumbs, title]);

  useEffect(() => {
    if (title || titleSuffix) {
      const suffix = titleSuffix ? ` | ${titleSuffix}` : ''
      window.document.title = title ? `${title}${suffix}` : `${titleSuffix}`;
    }
  }, [title, titleSuffix]);

  return (
    <PageContext.Provider value={contextValue}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;