import React, {FC, useMemo} from 'react';
import useApp from "~@app/component/app/UseApp"
import Image from "~@core/component/common/Image"
import {AnyString} from "~@core/type/Common"

export type LayoutBackgroundProps = {
  image?: AnyString
}
const LayoutBackground: FC<LayoutBackgroundProps> = ({image}) => {
  const app = useApp();
  const backgroundImg = useMemo(() => {
    return app.mediaUrl(image || 'static/media/image/app/default-banner.jpg');
  }, [image])


  return <div className="main-layout-bg">
    <Image url={backgroundImg}/>
  </div>
};

export default LayoutBackground;